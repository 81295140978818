<template>
  <div class="_zhanWrap">
    <phoneMenu :menu="p_menu" :p_current="p_current" @changeRoute="changeRoute"></phoneMenu>
    <div class="_banner" id="jbcl">
      <div class="_swiper">
        <el-carousel height="833px" :autoplay="false" arrow="never" ref="carousel" indicator-position="none">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">

            <div class="_nr">
              <img :src="$IMG_URL + item.cover" alt="">

              <div class="_cont">
                <h4>{{ item.title }}</h4>
                <p class="_pp" v-html="item.content">
                </p>

                <div class="_btn" @click="change('next')" v-show="index == 0">
                  <span>后一部分</span>
                  <img src="@/assets/images/jt.png" alt="">
                </div>

                <div class="_btn1" v-show="index == 1">
                  <div class="_btn_two1" @click="change('prev')">
                    <img src="@/assets/images/jt_l.png" alt="">
                    <span>上一部分</span>


                  </div>
                  <div class="_btn_two2" @click="change('next')">
                    <span>后一部分</span>
                    <img src="@/assets/images/jt.png" alt="">

                  </div>

                </div>



                <div class="_btn1 _btn2" v-show="index == 2 || index == 3">
                  <div class="_btn_two1" @click="change('prev')">
                    <img src="@/assets/images/jt_l.png" alt="">
                    <span>前一部分</span>


                  </div>
                  <div class="_btn_two2" @click="change('next')">
                    <span>后一部分</span>
                    <img src="@/assets/images/jt.png" alt="">

                  </div>

                </div>


                <div class="_btn btn_5" @click="change('prev')" v-show="index == 4">
                  <img src="@/assets/images/jt_l.png" alt="">

                  <span>前一部分</span>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>



    <div class="_ztWrap" id="ztzl">
      <div class="_ztAuto">
        <h4></h4>

        <div class="_list">
          <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item, '专题展览')">
            <div class="_left">
              <img :src="$IMG_URL + item.cover" alt="">

            </div>
            <div class="_right">
              <div class="_h4">{{ item.title }}</div>
              <div class="_p">
                {{ item.description }}
              </div>

              <div class="_time">
                <div class="_l">
                  <span><b>展览日期：</b>{{ item.exhibition_date_start }} · {{ item.exhibition_date_end }}</span>
                  <span class="_shu"></span>
                  <span class="_yj">
                    <img src="@/assets/images/yj.png" alt="">
                    <span>{{ item.hits }}</span>
                  </span>
                </div>

                <div class="_r">
                  <div class="_btn">阅读正文</div>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div class="_page">
          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.page_num"
            @pagination="getList" />
        </div>

      </div>

    </div>



    <div class="_lsWrap">
      <div class="_lsAuto" id="lszl">
        <h4></h4>
        <div class="_list">
          <div class="_li" v-for="(item, index) in list1" :key="index" @click="goDetail(item, '临时展览')">
            <div class="_img">
              <img :src="$IMG_URL + item.cover" alt="">
            </div>
            <div class="_h4">
              {{ item.title }}
            </div>
            <div class="_p">
              <span>展览日期：{{ item.exhibition_date_start }}~{{ item.exhibition_date_end }}</span>
              <div class="_hits">
                <img src="@/assets/images/yj.png" alt="">
                <span>{{ item.hits }}</span>
              </div>
            </div>
          </div>


        </div>


        <div class="_page">
          <pagination v-show="total1 > 0" :total="total1" :page.sync="queryParams1.page"
            :limit.sync="queryParams1.page_num" @pagination="getList1" />
        </div>
      </div>
    </div>

    <fixIcon />
    <coment ref="comentRef"></coment>
    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { getDataById, hits } from "@/api/getData"
import phoneMenu from '../../components/phoneMenu/phoneMenu.vue';
export default {
  components: {
    phoneMenu
  },
  data() {
    return {
      p_current: 0,
      cate_id: "14",
      obj: {
        '基本陈列': 'jbcl',
        '专题展览': 'ztzl',
        '临时展览': 'lszl'
      },
      total: 100,
      queryParams: {
        page: 1,
        page_num: 3,
        cate_id: "15",
      },

      total1: 100,
      queryParams1: {
        page: 1,
        page_num: 8,
        cate_id: "16",
      },
      bannerList: [
        {
          img: require("@/assets/images/banner1.png")
        },
        {
          img: require("@/assets/images/banner2.png")
        },
        {
          img: require("@/assets/images/banner3.png")
        },
        {
          img: require("@/assets/images/banner4.png")
        },
        {
          img: require("@/assets/images/banner5.png")
        },
      ],
      list: [],
      list1: [],
      p_menu: [
        {
          text: "基本陈列",
          path: "/visit"
        },
        {
          text: "专题展览",
          path: "/visit"
        },
        {
          text: "临时展览",
          path: "/visit"
        },

      ],


    }
  },

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit
      if (title) {
        for (let i in this.obj) {
          if (title == i) {
            this.$scrollToSection(this.obj[i])
          }
        }
        this.p_menu.forEach((item, index) => {
          if (title == item.text) {
            this.p_current = index
          }
        })

      } else {
        this.$scrollTop()

      }


    }
  },
  async mounted() {
    await this.getList0()
    await this.getList()
    await this.getList1()
    this.jumpId()
  },
  created() {
  },
  methods: {
    changeRoute(index) {
      this.p_current = index
    },
    jumpId() {
      const title = this.$route.query.tit

      if (title) {
        for (let i in this.obj) {
          // console.log(i)
          if (title == i) {
            this.$scrollToSection(this.obj[i])
          }
        }

      } else {
        this.$scrollTop()
      }

    },
    goDetail(item, tit) {
      let url = location.origin

      if (item.link != null && item.link != '') {
        const params = {
          id: item.id
        }
        hits(params)
        window.open(item.link)
      }
      window.open(`${url}/studyDetail?tit=${tit}&id=${item.id}`)
    },
    change(type) {
      this.$refs['carousel'][type]()
    },


    async getList0() {
      const params = {
        cate_id: this.cate_id,
        page: 1,
        page_num: 5,
        keywords: "",
        type_id: "",
        times: "",
      }

      await getDataById(params).then(res => {
        console.log(res)


        this.bannerList = res.data.data
      })
    },


    async getList() {
      const params = {
        cate_id: this.queryParams.cate_id,
        page: this.queryParams.page,
        page_num: this.queryParams.page_num,
        keywords: "",
        type_id: "",
        times: "",
      }

      await getDataById(params).then(res => {
        console.log(res)
        this.list = res.data.data
        this.total = res.data.total


      })
    },
    async getList1() {
      const params = {
        cate_id: this.queryParams1.cate_id,
        page: this.queryParams1.page,
        page_num: this.queryParams1.page_num,
        keywords: "",
        type_id: "",
        times: "",
      }

      await getDataById(params).then(res => {
        console.log(res)
        this.list1 = res.data.data
        this.total1 = res.data.total


      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

@mixin tit($url)
{
  width: 299px;
  height: 52px;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

._page
{
  text-align: center;

  @include respondTo('phone')
  {
    margin-bottom: 20px;
  }
}

._zhanWrap
{
  margin-top: 120px;
}

._banner
{
  ._nr
  {
    position: relative;

    ._cont
    {
      width: 560px;
      height: 630px;
      background: rgba(28, 32, 39, 0.85);
      position: absolute;
      bottom: 160px;
      left: 142px;
      z-index: 9;
      color: #F9DAA0;
      padding: 40px;

      h4
      {
        text-align: center;
        color: #FFF0C8;
        font-size: 24px;
        margin-bottom: 30px;
        font-weight: bold;
      }

      ._pp
      {
        font-size: 18px;
        text-indent: 2em;
        letter-spacing: 1px;
        line-height: 28px;
        // overflow: hidden;
        // display: -webkit-box;
        // -webkit-line-clamp: 13;
        // /* 设置最大显示行数 */
        // -webkit-box-orient: vertical;
        // text-overflow: ellipsis;
        overflow-y: auto;
        height: 400px;
      }

      ._btn
      {
        width: 480px;
        height: 72px;
        border-radius: 4px;

        border: 1px solid #F9DAA0;
        line-height: 72px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        margin-top: 30px;

        span
        {
          font-size: 26px;
        }

        img
        {
          // justify-self: unset;
          position: absolute;
          right: 20px;
          top: 20px;
          width: 32px;
          height: 32px;
          //border: 1px dashed #F9DAA0;

        }
      }

      ._btn:hover
      {
        background: #C0A062;
        color: white;

      }


      ._btn1
      {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        >div
        {
          width: 220px;
          height: 72px;
          line-height: 72px;
          text-align: center;
          border-radius: 4px 4px 4px 4px;

          border: 1px solid #F9DAA0;
          position: relative;
          cursor: pointer;

          span
          {
            font-size: 26px;
          }

          img
          {
            vertical-align: middle;
            // border: 1px dashed #F9DAA0;

          }
        }

        >div:hover
        {
          background: #C0A062;
          color: white;

        }

        ._btn_two1
        {
          img
          {
            position: absolute;
            left: 1ch;
            top: 20px;
          }
        }

        ._btn_two2
        {

          // background: #C0A062;
          // color: white;


          img
          {
            position: absolute;
            right: 10px;
            top: 20px;
          }
        }
      }

      ._btn2
      {
        ._btn_two2
        {
          background: transparent;
          color: #F9DAA0;
        }
      }

      .btn_5
      {
        img
        {
          left: 20px;
          border: none;
        }
      }

    }
  }
}


._ztWrap
{
  margin-top: 80px;
  margin-bottom: 40px;

  ._ztAuto
  {
    width: 85%;
    margin: 0 auto;

  }

  h4
  {
    @include tit("../../assets/images/h4-4.png");
    margin: 0 auto;
    margin-bottom: 60px;
  }

  ._list
  {

    ._li
    {
      display: flex;
      cursor: pointer;
      margin: 40px 0;

      ._left
      {
        width: 330px;
        height: 185px;
        overflow: hidden;

        img
        {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all .5s;
        }

        img:hover
        {
          transform: scale(1.1);
        }
      }

      ._right
      {
        flex: 1;
        margin-left: 25px;

        ._h4
        {
          font-size: 28px;
          overflow: hidden;

          white-space: nowrap;
          text-overflow: ellipsis;
        }

        ._p
        {
          color: #333;
          font-size: 18px;
          margin: 16px 0 40px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;

        }

        ._time
        {
          color: #666;
          display: flex;
          justify-content: space-between;
          align-items: center;

          ._l
          {
            display: flex;
            align-items: center;

            b
            {
              font-weight: normal;
            }

            ._shu
            {
              display: inline-block;
              width: 1px;
              height: 12px;
              background-color: #ccc;

              margin: 0 20px;
            }

            img
            {
              vertical-align: middle;
            }
          }

          ._r
          {
            ._btn
            {
              width: 132px;
              height: 40px;
              background-image: url("../../assets/images/lingx.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              color: white;
              text-align: center;
              line-height: 40px;
            }
          }

        }

      }
    }
  }
}


._lsWrap
{
  width: 100%;
  background-color: #F8F5EE;
  overflow: hidden;

  ._lsAuto
  {
    width: 85%;
    margin: 0 auto;

    h4
    {
      @include tit("../../assets/images/h4-5.png");
      margin: 0 auto;
      margin-bottom: 90px;
      margin-top: 60px;
    }

    ._list
    {
      display: flex;
      flex-wrap: wrap;

      ._li
      {
        // width: 380px;
        width: 23.4%;
        cursor: pointer;
        margin: 25px 0;
        // margin-right: 33px;
        margin-right: 2.13%;

        ._img
        {
          // width: 380px;
          width: 100%;
          height: 213px;
          overflow: hidden;

          img
          {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .5s;
          }

          img:hover
          {
            transform: scale(1.1);
          }
        }

        ._h4
        {
          font-size: 20px;
          margin: 8px 0;
          overflow: hidden;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // /* 设置最大显示行数 */
          // -webkit-box-orient: vertical;
          white-space: nowrap;
          text-overflow: ellipsis;

        }

        ._p
        {
          color: #666;
          display: flex;
          justify-content: space-between;
          align-items: center;

        }


        &:nth-child(4n)
        {
          margin-right: 0;
        }
      }


    }
  }
}


._pp::-webkit-scrollbar
{
  width: 8px;
  height: 10px;
  background-color: transparent;
}

._pp::-webkit-scrollbar-thumb
{
  border-radius: 20px;
  background-color: rgba(144, 147, 153, .3);
}

._zhanWrap
{
  @include respondTo('phone')
  {
    margin-top: 60px;
  }
}

::v-deep .el-carousel__container
{
  @include respondTo('phone')
  {
    height: 500px !important;
  }
}

._banner ._nr
{
  @include respondTo('phone')
  {

    >img
    {
      width: 100%;
      height: 190px;
      object-fit: cover;
    }

    ._cont
    {
      position: initial;
      width: 100%;
      padding: 10px;
      // height: 360px;
      height: auto;
      margin-top: -5px;

      h4
      {
        margin-bottom: 5px;
      }

      ._pp
      {
        height: 200px;
      }

      ._btn
      {
        width: 100%;
        height: 46px;
        line-height: 46px;
        margin-top: 10px;

        span
        {
          font-size: 20px;
        }

        img
        {
          top: 8px;
        }
      }

      ._btn1
      {
        margin-top: 10px;
      }

      ._btn1>div
      {
        width: 47%;
        height: 46px;
        line-height: 46px;
        text-align: center;

        img
        {
          top: 8px !important;
        }

        span
        {
          font-size: 20px;

        }
      }
    }


  }

}

._ztWrap
{
  @include respondTo('phone')
  {
    margin-top: 20px;
    margin-bottom: 0;

    ._ztAuto
    {
      width: 95%;

      >h4
      {
        background-image: url("../../assets/images/phone/zttit.png");
        width: 173px;
        height: 30px;
        margin-bottom: 0;
      }

      ._list
      {
        ._li
        {
          margin: 25px 0;
          border-bottom: 1px dashed #ccc;
          padding-bottom: 10px;

          ._left
          {
            width: 30%;
            height: 74px;

          }

          ._right
          {
            width: 65%;
            margin-left: 10px;

            ._h4
            {
              font-size: 18px;
              font-weight: bold;
            }

            ._p
            {
              -webkit-line-clamp: 1;
              margin: 6px 0;
            }

            ._time
            {
              ._l
              {
                b
                {
                  display: none;
                }

                ._shu
                {
                  display: none;
                }

                ._yj
                {
                  display: none
                }
              }

              ._r
              {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

._lsWrap
{
  @include respondTo('phone')
  {
    ._lsAuto
    {
      width: 95%;

      >h4
      {
        background-image: url("../../assets/images/phone/lszltit.png");
        width: 173px;
        height: 30px;
        margin-top: 30px;
        margin-bottom: 20px;
      }

      ._list
      {
        justify-content: space-between;

        ._li
        {
          width: 48%;
          margin: 10px 0;

          ._img
          {
            height: 98px;
          }

          ._p
          {
            display: none;
          }

        }
      }
    }
  }
}
</style>